import "./App.css";

import { socket } from "./Socket";
import { useLayoutEffect, useState } from "react";
import logo from "./devstyle.svg";
import ds from "./ds.svg";

const App = () => {
  const params = new URLSearchParams(window.location.search);

  const [image, setImage] = useState("");
  const [mode, setMode] = useState("");
  const id = params.get("id");

  useLayoutEffect(() => {
    setMode("loaded");
    socket.emit("execute", id);

    socket.on(id, (data) => {
      if (data === "processing") {
        setMode("processing");
      } else {
        setMode("loaded");
      }
      if (data?.url) {
        setImage(data.url);
        socket.close(id);
      }
    });

    socket.on("connect_error", () => {
      socket.close(id);
      setMode("offline")
    });

    return () => {
      socket.close(id);
    };
  }, [id]);

  return (
    <div className="grow flex flex-col  gap-8 p-8 text-white w-full overflow-clip relative min-h-screen ">
      <div
        className={`absolute top-0 left-0 w-full h-full bg-[#333] transition-opacity ${
          mode === "processing" ? "opacity-100" : "opacity-0"
        } bg-opacity-30 pointer-events-none z-10 flex justify-center items-center font-bold backdrop-blur-md`}
      >
        <img src={ds} className="w-32 h-32 animate-bounce" alt="" />
      </div>

      <img src={logo} alt="devstyle" className="w-32" />
      {(image && id) || (id && mode === 'offline') ? (
        <>
          <h1 className="text-2xl font-medium text-center">
            Zapraszamy po odbiór niespodzianki do kiosku obok!
          </h1>
          <img
            className="max-w-[77%] aspect-[1.2/1.7] w-full h-full block mx-auto"
            alt=""
            src={`https://devstyle-kiosk.s3.eu-north-1.amazonaws.com/${id}.png`}
          />
          <a
            className="inline-block bg-[#D13820] border border-transparent text-center text-white transition-colors font-bold py-3 px-6 rounded-lg "
            href={`https://devstyle-kiosk.s3.eu-north-1.amazonaws.com/${id}.png`}
          >
            Pobierz okładkę &darr;
          </a>  <a
            className="inline-block bg-[#D13820] border border-transparent text-center text-white transition-colors font-bold py-3 px-6 rounded-lg "
            href={`https://prenumerata.devstyle.pl`}
          >
            Prenumeruj Devstyle &rarr;
          </a>
        </>
      ) : !image && id && (mode !== 'offline') ? (
        <h1 className="text-2xl font-medium text-center">
          📸 <br />
          <br /> Spójrz na TV!{" "}
        </h1>
      ) : (
        <h1>Zapraszamy na stoisko devstyle!</h1>
      )}
    </div>
  );
};

export default App;
